import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import './style.scss'

import css from "./style.module.scss"

import { Button, Container, H1, MasterPage, TextInput, Select, WomanOutlined, ManOutlined, SelectOption } from "../../components";
import Fatherhood from "../../assets/fatherhood.svg";
import axios from "axios"

import { Select as S2 } from "antd";

const handleInputChange = event => {
  const target = event.target
  const value = target.value
  return value;
}


const IndexPage = (props) => {

  const data = useStaticQuery(graphql`query assets2 {
        site {
          siteMetadata {
            agent {
                assets {
                    image
                },
                imageSrc,
                name,
                phone,
                whatsapp,
                email,
                website,
                title,
            }
          }
        }
      }
    `)

  const [gender, setGender] = useState()
  const [age, setAge] = useState()
  const [email, setEmail] = useState("")
  const [zip_code, setZipCode] = useState("")
  const [income, setIncome] = useState("")
  const [county, setCounty] = useState(null)
  const [avaiableCounties, setAvaiableCounties] = useState([])


  const handleZipCodeChange = async (event) => {
    setZipCode(handleInputChange(event))
    if (!(event.target && event.target.value && event.target.value.length === 5)) {
      setAvaiableCounties([]);
      setCounty(null);
      return;
    }

    const response = await axios({
      method: "get",
      url: `https://api.qa.apolloquotes.com/check-zip-code/${event.target.value}`,
    })


    if (response.status == 200 && response.data.status != "400" && response.data.counties.length > 0) {

      const { counties } = response.data;
      setCounty(counties[0])
      setAvaiableCounties(counties)
    } else {
      setAvaiableCounties([])
      setCounty(null);
    }

  }

  return (
    <MasterPage data={data}>
      {/* <Helmet>
          <meta viewport="width=device-width, initial-scale=1, minimum-scale=1,maximum-scale=1, shrink-to-fit=no"/>
        </Helmet> */}
      {/* //<SEO title="Home" /> */}
      <Container className="main-container">
        <Container className="headings-container">
          <H1 className="health-insurance" color="blue"> Health insurance</H1>
          <H1 className="quotes" color="blue"> Quotes and</H1>
          <H1 className="plans" color="gray"> Plans</H1>
        </Container>
        <Container className="content-container">
          <Container className="illustration">
            {data.site.siteMetadata?.agent?.imageSrc ? <img src={data.site.siteMetadata?.agent?.imageSrc} /> : <Fatherhood />}
          </Container>
          <Container className="form">
            <Container className="inputs">
              <TextInput value={zip_code} placeholder="Zip Code" pattern="[0-9]*" onChange={(event) => {
                handleZipCodeChange(event);
              }} />
              {
                avaiableCounties && avaiableCounties.length > 0 && <Select style={{ width: "100%" }} size="large" options={
                  avaiableCounties.map(a => {
                    return {
                      label: a.name,
                      value: a.name,
                    }
                  })}

                  value={county ? county.name : null}

                  defaultValue={avaiableCounties[0].name}

                  onChange={(v) => {
                    setCounty(avaiableCounties.filter(f => f.name == v)[0])
                  }}

                />
              }
              <Container className={css.inputContainer}>
                <Container style={{ display: "flex", flexGrow: 1 }}>
                  <Select placeholder="Gender" size="large" value={gender} allowClear showArrow={!gender} onChange={setGender} dropdownStyle={{ background: "#fff" }}>
                    <SelectOption className={`${css.bgWhite}`} value="Male">
                      <Container className={`${css.genderInputMaleContainer} ${gender === "Male" ? css.maleActive : ""}`}>
                        Male
                    <ManOutlined />
                      </Container>
                    </SelectOption>
                    <SelectOption className={css.bgWhite} value="Female">
                      <Container className={`${css.genderInputFemaleContainer} ${gender === "Female" ? css.femaleActive : ""}`}>
                        Female
                    <WomanOutlined />
                      </Container>
                    </SelectOption>
                  </Select>
                </Container>
              </Container>
              <TextInput value={age} onChange={(event) => { setAge(handleInputChange(event)) }} type="number" placeholder="Age" />
              <TextInput value={income} onChange={(event) => { setIncome(handleInputChange(event)) }} type="number" placeholder="Income" />
              <TextInput value={email} onChange={(event) => { setEmail(handleInputChange(event)) }} type="email" placeholder="Email (Optional)" />
            </Container>

            {county && age ? <Link to={`/quotes?income=${income}&gender=${gender}&age=${age}&email=${email}&zip_code=${zip_code}&fips_code=${county.fips}&state=${county.state}`} className="apollo-button-container">
              <Button shape="round" onClick={() => { }} type="primary">
                GET A QUOTE
                </Button>
            </Link> : <div className="apollo-button-container">
                <Button disabled style={{ opacity: 0.7, background: "#1B75BC", color: "#fff" }} shape="round" onClick={() => { }} type="primary">
                  GET A QUOTE
                </Button> </div>}

          </Container>

        </Container>
      </Container>
    </MasterPage>
  )
}

export default IndexPage
